import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCard, Pagination, PostFeed } from '../components/common'
import { MetaData } from '../components/common/meta'
import Gokart from '../components/tracks/Gokart'
import racingImage from '../images/racing-1600x900.jpg'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location, pageContext }) => {
  const geo = data.geo
  const indexTitle = "Go Karting, and Race Tracks Near You"
  const indexDesc = "Find go kart and car tracks for all your favorite races in your city. Join races with Open Racer."
  const tracks = pageContext.tracks?.map(track => track.document)

    return (
        <>
            <MetaData 
                location={location} 
                title={geo ? `${geo.city} Go Karting Tracks and Racing Lines` : indexTitle}
                description={geo ? `Find go karting tracks near you. Motorsport tracks and events in ${geo.city}, ${geo.country}.` : indexDesc}
                image={racingImage}
                />
            <Layout>
                    <Gokart geo={geo} tracks={tracks}/>
                    <PostFeed posts={data.allGhostPost.edges} pageContext={pageContext} />
            </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!, $filename: String = "") {
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    geo: worldcitiesFilteredCsv(filename: {eq: $filename}) {
        filename
        city
        lat
        lng
        city_ascii
        country
    }
  }
`
